import { capitalize } from '@/components/formatters/utils';

/* eslint-disable no-shadow */
export enum AlarmState {
  OK = 'ok',
  ACTIVE = 'active',
  RESOLVED = 'resolved',
}

export enum AnalyticAlarmType {
  AGGREGATION = 'aggregation',
  UNIVARIATE_ANOMALY = 'univariate_anomaly',
  MULTIVARIATE_ANOMALY = 'multivariate_anomaly'
}

export enum MetricAggregations {
  min = 'min',
  max = 'max',
  sum = 'sum',
  avg = 'avg',
  count = 'count',
}

export enum TriggerOperator {
  lt = '<',
  gt = '>',
  lte = '=<',
  gte = '>=',
  eq = '==',
  ne = '!='
}

export enum CollectionType {
  CLIMATE = 'climate',
  ANALYTICS = 'analytics',
  ENERGY_PRICES = 'energy_prices',
  METER = 'meter',
  CONSUMPTION = 'consumption',
  WEATHER_FORECAST = 'weather_forecast',
}

export type MultivariateAnomalyModelStatus = 'PENDING' | 'CREATED' | 'RUNNING' | 'READY' | 'FAILED' | 'DETECTION_FAILED';

export interface AnalyticsAlarmsDefinition {
  id: string;
  orgId: string;
  propertyId: string;
  name: string;
  description?: string | null;
  priority: number;
  type: AnalyticAlarmType;
  aggregationMeasurementTypes?: string[] | null;
  aggregationFunction?: MetricAggregations | null;
  aggregationTriggerOperator?: TriggerOperator | null;
  aggregationTriggerValue?: number | null;
  aggregationTriggerInterval?: number | null;
  univariateAnomalyMeasurementDeviceId?: string | null;
  univariateAnomalyWindow?: number | null;
  univariateAnomalyMaxAnomalyRatio?: number | null;
  univariateAnomalySensitivity?: number | null;
  multivariateAnomalyTraningStartTime?: Date | null;
  multivariateAnomalyTraningEndTime?: Date | null;
  multiVariateAnomalyMeasurementDeviceSetId?: string | null;
  multiVariateAnomalyModelStatus?: MultivariateAnomalyModelStatus | null;
  multiVariateAnomalyModelId?: string | null;
  multiVariateAnomalyModelErrors?: {
      code: string;
      message: string;
  }[] | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface DeviceAlarmsDefinition{
  id: string;
  orgId: string;
  propertyId: string;
  deviceId: string;
  technicalDataPointId?: string | null;
  technicalConnectionId?: string | null;
  technicalSystemId?: string | null;
  unitId: string;
  nodeId: string;
  externalId: string;
  name: string;
  description?: string | null;
  priority: number;
  class?: string | null;
  source?: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface Alarm {
  id: string;
  priority: number;
  class?: string | null;
  source?: string | null;
  orgId: string;
  propertyId: string;
  deviceDefinitionId?: string | null;
  analyticDefinitionId?: string | null;
  technicalDataPointId?: string | null;
  technicalConnectionId?: string | null;
  technicalSystemId?: string | null;
  deviceId: string | null;
  unitId: string | null;
  measurementDeviceId?: string | null;
  backgroundJobId?: string | null;
  serviceConnectionId?: string | null;
  state: AlarmState;
  activatedAt?: string | null;
  resolvedAt?: string | null;
  data?: string | null;
  createdAt: string;
  updatedAt: string;
  deviceAlarmDefinition: DeviceAlarmsDefinition;
  analyticAlarmDefinition: AnalyticsAlarmsDefinition;
}

export enum TechnicalSystemsTypes {
  VENTILATION = 'ventilation',
  HEATING = 'heating',
  COOLING = 'cooling',
  HEAT_COOL = 'heat_cool',
  LIGHTING = 'lighting',
  ELECTRICAL = 'electrical',
  COMMUNICATION = 'communication',
  SOUND = 'sound',
  AUTOMATION = 'automation',
  ENERGY = 'energy',
  WASTE = 'waste',
  ACCESS = 'access',
  WATER = 'water',
  WASTEWATER = 'wastewater',
}
export const TechnicalSystemsTypesNames = [
  { value: 'ventilation', text: 'Ventilation' },
  { value: 'heating', text: 'Heating' },
  { value: 'cooling', text: 'Cooling' },
  { value: 'heat_cool', text: 'Heat & Cool' },
  { value: 'lighting', text: 'Lighting' },
  { value: 'electrical', text: 'Electrical' },
  { value: 'communication', text: 'Communication' },
  { value: 'sound', text: 'Sound' },
  { value: 'automation', text: 'Automation' },
  { value: 'energy', text: 'Energy' },
  { value: 'waste', text: 'Waste' },
  { value: 'access', text: 'Access' },
  { value: 'water', text: 'Water' },
  { value: 'wastewater', text: 'Wastewater' },
];

export interface TechnicalSystem {
  id: string;
  orgId: string;
  propertyId: string;
  name: string;
  label?: string | null;
  description?: string | null;
  unitId?: string | null;
  scheduleId?: string | null;
  type: TechnicalSystemsTypes;
  createdAt: string;
  updatedAt: string;
}

export interface TechnicalConnection {
  id: string;
  orgId: string;
  propertyId: string;
  deviceId: string;
  name: string;
  type: string;
  data: Record<string, any>;
  createdAt: string;
  updatedAt: string;
}

export interface TechnicalSystemDevicesConnection {
  id: string,
  name: string,
  serialNumber: string,
  technicalConnections: TechnicalConnection[]
}

export interface VisualItem {
  id: string;
  technicalSystemId: string;
  technicalDataPointId: string;
  technicalDataPoint: any,
  label: string;
  s3objectkey: string;
  x: string;
  y: string;
  zIndex: number,
  width: string;
  height: string
}

export enum MeasurementDeviceFlowDirection {
  GRID_IMPORT = 'grid-import',
  GRID_EXPORT = 'grid-export',
  PRODUCER = 'producer',
  CONSUMER = 'consumer',
  STORAGE_IMPORT = 'storage-import',
  STORAGE_EXPORT = 'storage-export'
}

export interface MeasurementDevice {
  measurementDeviceType: string,
  uniqueName: string,
  unitId?: string,
  valueUnit: string,
  nodeId: string,
  deviceId: string,
  orgId: string,
  label: string,
  interval: number,
  collectionType: string,
  propertyId: string,
  id: string
  technicalSystemId?: string;
  flowDirection?: MeasurementDeviceFlowDirection;
}

export type MeterRanges = 'date'|'month'|'year';

export const DEFAULT_UNIT = '00000000-0000-0000-0000-000000000000';

export interface DatapointAlarmData {
  class?: string;
  source?: string;
  description?: string;
  name?: string;
  priority?: string;
}

// 'occupied' is the "normal" state. Deviations means "different than occupied"
export enum DeviationMode {
  OCCUPIED = 'occupied',
  UNOCCUPIED = 'unoccupied',
  //
  BUSY = 'busy',
  QUIET = 'quiet',
  //
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
  //
  BLOCKED = 'blocked',
  UNBLOCKED = 'unblocked',
  //
  IMPORT = 'import',
  EXPORT = 'export',
  //
  CHARGE = 'charge',
  DISCHARGE = 'discharge',
  //
  STABLE = 'stable',
  UNSTABLE = 'unstable',
  //
  ON = 'on',
  OFF = 'off',
  //
  OPEN = 'open',
  CLOSED = 'closed',
  //
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  //
  ENGAGED = 'engaged',
  DISENGAGED = 'disengaged',
  //
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  //
  STARTED = 'started',
  STOPPED = 'stopped',
  //
  PRESENT = 'present',
  ABSENT = 'absent',
  //
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  //
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  //
  READY = 'ready',
  NOT_READY = 'not_ready',
}
export const availableDeviationColors = [
  'var(--lar-color-primary)',
  'var(--lar-color-secondary)',
  'var(--lar-color-tertiary)',
  'var(--lar-color-warning)',
  'var(--lar-color-danger)',
  'var(--lar-color-dark)',
  'var(--purple-50)',
  'var(--purple-100)',
  'var(--purple-200)',
  'var(--purple-300)',
  'var(--purple-400)',
  'var(--purple-500)',
  'var(--purple-600)',
  'var(--purple-900)',
  'var(--orange-50)',
  'var(--orange-100)',
  'var(--orange-200)',
  'var(--orange-300)',
  'var(--orange-400)',
  'var(--orange-500)',
  'var(--orange-600)',
  'var(--orange-900)',
  'var(--pink-50)',
  'var(--pink-100)',
  'var(--pink-200)',
  'var(--pink-300)',
  'var(--pink-400)',
  'var(--pink-500)',
  'var(--pink-600)',
  'var(--pink-900)',
  'var(--blue-50)',
  'var(--blue-100)',
  'var(--blue-200)',
  'var(--blue-300)',
  'var(--blue-400)',
  'var(--blue-500)',
  'var(--blue-600)',
  'var(--blue-900)',
  'var(--red-50)',
  'var(--red-100)',
  'var(--red-200)',
  'var(--red-300)',
  'var(--red-400)',
  'var(--red-500)',
  'var(--red-600)',
  'var(--red-900)',
];

export function getDeviationModeLabel(type: string) {
  return capitalize(type.replace('_', ' '));
}
export enum DeviationType {
  NONE = 'none',
  OFFSET = 'offset',
  OVERRIDE = 'override',
}

export interface DeviationModeData {
  type: DeviationType;
  value: number
}

export type DeviationData = {
 [key: string]: DeviationModeData;
};

export interface TechnicalDataPoint {
  id: string;
  orgId: string;
  propertyId: string;
  technicalSystemId: string;
  unitId: string;
  connectionId: string;
  name: string;
  accessType: string;
  analyticsInterval?: number | null;
  analyticsCollectionType?: CollectionType | null;
  analyticsMeasurementDeviceType?: string | null;
  analyticsValueUnit?: string | null;
  analyticsFlowDirection?: MeasurementDeviceFlowDirection | null;
  writeInterval?: number | null;
  writeInputType: string;
  writeInputData?: Record<string, any> | null;
  scheduleId?: string | null;
  deviationData?: DeviationData | null;
  alarmData?: DatapointAlarmData | null;
  data: Record<string, any>;
  createdAt: string;
  updatedAt: string;
}

export function getTypeIcon(type: string) {
  switch (type) {
    case 'temperature':
    case 'temp':
      return 'temperature-half';
    case 'hum':
    case 'humidity':
    case 'rel_humidity':
    case 'abs_humidity':
      return 'droplet';
    case 'power':
    case 'energy':
    case 'electricity':
    case 'amps':
    case 'voltage':
    case 'watt':
    case 'on_off':
    case 'on':
    case 'onoff':
      return 'plug';
    case 'co':
    case 'co2':
    case 'voc':
      return 'leaf';
    case 'lux':
      return 'fa-regular fa-lightbulb';
    // @TODO: more types
    default: return 'magnifying-glass-chart';
  }
}

export function getTypeLabel(type: string) {
  if (!type || typeof type !== 'string') {
    return ('Invalid');
  }
  switch (type) {
    case 'electricity':
      return ('Electricity');
    case 'electricity_day':
      return ('Electricity day');
    case 'electricity_night':
      return ('Electricity night');
    case 'water':
      return ('Water');
    case 'warm_water':
      return ('Warm Water');
    case 'cold_water':
      return ('Cold Water');
    default: return capitalize(type.replace('_', ' '));
  }
}

export function technicalSystemIcon(type: TechnicalSystemsTypes) {
  switch (type) {
    case TechnicalSystemsTypes.ACCESS:
      return 'door-open';
    case TechnicalSystemsTypes.AUTOMATION:
      return 'gears';
    case TechnicalSystemsTypes.COMMUNICATION:
      return 'network-wired';
    case TechnicalSystemsTypes.COOLING:
      return 'snowflake';
    case TechnicalSystemsTypes.ELECTRICAL:
    case TechnicalSystemsTypes.ENERGY:
      return 'bolt';
    case TechnicalSystemsTypes.HEATING:
      return 'fire';
    case TechnicalSystemsTypes.HEAT_COOL:
      return 'temperature-half';
    case TechnicalSystemsTypes.LIGHTING:
      return 'fa-regular fa-lightbulb';
    case TechnicalSystemsTypes.SOUND:
      return 'volume-low';
    case TechnicalSystemsTypes.VENTILATION:
      return 'fan';
    case TechnicalSystemsTypes.WASTE:
    case TechnicalSystemsTypes.WASTEWATER:
      return 'recycle';
    case TechnicalSystemsTypes.WATER:
      return 'water';
    default:
      return 'gears';
  }
}

// 'occupied' is the "normal" state. Deviations means "different than occupied"
export enum DeviationDay {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
  // HOLIDAY = 'holiday',
}

export interface Schedule {
  id: string;
  orgId: string;
  propertyId: string;
  name: string;
  description?: string | null;
  deviationModes: DeviationMode[],
  generationInterval: number,
  generationTime: string,
  lastGeneratedDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface Deviation {
  id: string;
  orgId: string;
  propertyId: string;
  scheduleId: string;
  mode: DeviationMode;
  day: DeviationDay;
  minutes: number[];
  createdAt: string;
  updatedAt: string;
}

export interface DeviationEntry {
  id: string;
  orgId: string;
  propertyId: string;
  scheduleId: string;
  range: [Date, Date];
  colorIndex: number;
  createdAt: string;
  updatedAt: string;
}

export interface DeviationEntryAction {
  id: string;
  orgId: string;
  propertyId: string;
  technicalSystemId: string;
  deviationEntryId: string;
  technicalDataPointId: string;
  mode: DeviationType;
  value: number;
  createdAt: string;
  updatedAt: string;
}
