import './plugins/fontawesome';
import './plugins/pwa-install-promopt';
import './register-service-worker';
import './plugins/vue-drag';

import { createApp } from 'vue';

// main app
import moment from 'moment';
import App from './App.vue';
import DateHelper from './components/helpers/date';
// notifications plugin
import messaging from './plugins/firebase';
import http from './plugins/http';
// other plugins
import I18next from './plugins/i18next';
import LarvaWebcomponents from './plugins/larva-webcomponents';
import LarvaWebcomponentsCognitoLogin from './plugins/larva-webcomponents-cognito-login';
// plugins
import {
  BadgeDirective,
  ConfirmationService, DialogService, eventBus,
  PrimeVue, Theme, ToastService, Tooltip,
} from './plugins/primevue';
// router and store
// eslint-disable-next-line import/no-cycle
import Router from './router';
import Store from './store';

moment.updateLocale('en', {
  // Locale#week.dow representing the first day of the week, 0 is Sunday, 1 is Monday, ..., 6 is Saturday.
  week: {
    dow: 1,
  },
});

const app = createApp(App)
  // @TODO: use locale from i18n and translate primeVue, @see https://primefaces.org/primevue/locale
  .use(PrimeVue, {
    theme: {
      preset: Theme,
      options: {
        prefix: 'p',
        darkModeSelector: 'app-dark',
        cssLayer: false,
      },
    },
    // passthrough changes for our theme
    pt: {
      button: {
        root: { class: 'whitespace-nowrap' },
      },
      multiselect: {
        root: { class: 'w-full' },
        label: { class: 'flex-wrap' },
      },
      accordionheader: {
        root: { class: '!bg-transparent !text-surface-900 !flex-row-reverse !justify-end !gap-1 !uppercase !text-sm !font-semibold' },
      },
      message: {
        root: { class: 'my-4' },
        text: {
          class: 'whitespace-normal',
        },
      },
      inputtext: {
        root: { class: 'w-full' },
      },
      inputnumber: {
        root: { class: 'w-full' },
      },
      password: {
        root: { class: 'w-full' },
      },
      textarea: {
        root: { class: 'w-full' },
      },
      datepicker: {
        root: { class: 'w-full' },
      },
      dialog: {
        root: { class: 'max-w-[800px] w-[95vw]' },
      },
    },
    locale: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      dayNames: DateHelper.dayNames,
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: 'Today',
      weekHeader: 'Wk',
      firstDayOfWeek: 0,
      dateFormat: DateHelper.defaultDateFormatPrimeVue,
      hourFormat: DateHelper.defaultDateTimeFormatMoment,
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      passwordPrompt: 'Enter a password',
      emptyFilterMessage: 'No results found',
      emptyMessage: 'No available options',
      aria: {
        star: '1 star',
        stars: '{star} stars',
        pageLabel: '{page}',
      },
    },
  })
  .use(I18next)
  .use(ConfirmationService)
  .use(ToastService)
  .use(DialogService)
  .use(LarvaWebcomponentsCognitoLogin)
  .use(LarvaWebcomponents)
  .use(Store)
  .use(Router)
  .provide('messaging', messaging)
  .provide('eventBus', eventBus)
  .provide('http', http)
  .directive('tooltip', Tooltip)
  .directive('badge', BadgeDirective);

Router.isReady()
  .then(() => customElements.whenDefined('lar-app')) // @TODO: implement isReady on a plugin, since vue is not waiting for async plugin install method
  .then(() => customElements.whenDefined('lar-cognito-config')) // @TODO: implement isReady on a plugin, since vue is not waiting for async plugin install method
  .then(() => app.mount('#app'))
  .catch((err: Error) => {
    console.error('Error', err);
    // eslint-disable-next-line no-alert
    alert(err.message);
  });
